import React from "react"
import closeIcon from '../images/close.svg'
import {getDates, getVisitDate} from '../utilities'

export default function Transcription(props) {
  const dates = getDates(
    props.card.visitor.birth_date,
    props.card.visitor.death_date
  )
  const visitDate = getVisitDate(
    props.card.visit_date_year,
    props.card.visit_date_month,
    props.card.visit_date_day
  )
  
  return (
    <div className="transcription-wrapper">
    <div className="transcription">
      <div className="transcription--close">
        <img src={closeIcon} alt="Close" onClick={() => props.setShowTranscription(current => !current)} />
      </div>
      <div className="transcription--content">

        {
          // Front of card
        }

        {
          (props.card.front_printed_text
          || props.card.front_printed_text_translation
          || props.card.front_visitor_note
          || props.card.front_visitor_note_translation
          || props.card.front_zunz_note
          || props.card.front_zunz_note_translation)
          && 

            <section className="transcription--content--card-box">

              <h2 className="transcription--content--card-box--heading">Card Text</h2>

              <div className="transcription--content--card-box--inner">

                {props.card.front_printed_text &&
                  <div className="transcription--content--field">
                    <h3>Printed Text</h3>
                    <p>{props.card.front_printed_text}</p>
                    {props.card.front_printed_text_translation &&
                      <div>
                        <h3><i>Translation</i></h3>
                        <p>{props.card.front_printed_text_translation}</p>
                      </div>
                    }
                  </div>
                }
                {props.card.front_visitor_note &&
                  <div className="transcription--content--field">
                    <h3>Handwritten Note by Visitor</h3>
                    <p>{props.card.front_visitor_note}</p>
                    {props.card.front_visitor_note_translation &&
                      <div>
                        <h3><i>Translation</i></h3>
                        <p>{props.card.front_visitor_note_translation}</p>
                      </div>
                    }
                  </div>
                }
                {props.card.front_zunz_note &&
                  <div className="transcription--content--field">
                    <h3>Handwritten note by Leopold Zunz</h3>
                    <p>{props.card.front_zunz_note}</p>
                    {props.card.front_zunz_note_translation &&
                      <div>
                        <h3><i>Translation</i></h3>
                        <p>{props.card.front_zunz_note_translation}</p>
                      </div>
                    }
                  </div>
                }

              </div>

            </section>

        }

        {
          // Back of card
        }

        {
          (props.card.back_printed_text
          || props.card.back_printed_text_translation
          || props.card.back_visitor_note
          || props.card.back_visitor_note_translation
          || props.card.back_zunz_note
          || props.card.back_zunz_note_translation)
          && 

            <section className="transcription--content--card-box">

              <h2 className="transcription--content--card-box--heading">Card Text - Back</h2>

              <div className="transcription--content--card-box--inner">

              {props.card.back_printed_text &&
                <div className="transcription--content--field">
                  <h3>Printed Text</h3>
                  <p>{props.card.back_printed_text}</p>
                  {props.card.back_printed_text_translation &&
                    <div>
                      <h3><i>Translation</i></h3>
                      <p>{props.card.back_printed_text_translation}</p>
                    </div>
                  }
                </div>
              }
              {props.card.back_visitor_note &&
                <div className="transcription--content--field">
                  <h3>Handwritten Note by Visitor</h3>
                  <p>{props.card.back_visitor_note}</p>
                  {props.card.back_visitor_note_translation &&
                    <div>
                      <h3><i>Translation</i></h3>
                      <p>{props.card.back_visitor_note_translation}</p>
                    </div>
                  }
                </div>
              }
              {props.card.back_zunz_note &&
                <div className="transcription--content--field">
                  <h3>Handwritten note by Leopold Zunz</h3>
                  <p>{props.card.back_zunz_note}</p>
                  {props.card.back_zunz_note_translation &&
                    <div>
                      <h3><i>Translation</i></h3>
                      <p>{props.card.back_zunz_note_translation}</p>
                    </div>
                  }
                </div>
              }

              </div>

            </section>

        }

        {
          // Visitor
        }

        {
        //<h2 className="transcription--content--heading">Visitor :</h2>
        }

        <p className="transcription--content--visitor-name">
          {props.card.visitor.title} {props.card.visitor.first_name} {props.card.visitor.last_name} {dates && `${dates}`}
        </p>
        {props.card.visitor.profession_or_relationship &&
          <div className="transcription--content--field">
            <h3>Profession/relationship to Zunz</h3>
            <p>{props.card.visitor.profession_or_relationship}</p>
          </div>
        }
        {props.card.visitor.bio &&
          <div className="transcription--content--field">
            <h3>Biography</h3>
            <p>{props.card.visitor.bio}</p>
          </div>
        }
        {props.card.visitor_place &&
          <div className="transcription--content--field">
            <h3>Place associated with visitor</h3>
            <p>{props.card.visitor_place}</p>
          </div>
        }
        {visitDate &&
          <div className="transcription--content--field">
            <h3>Visit/event date</h3>
            <p>{visitDate}</p>
          </div>
        }

        {
          // Additional visitor
        }

        {props.card.additional_visitor &&
          <div>
            <hr />
            <h2 className="transcription--content--heading">Additional visitor :</h2>

            <div className="transcription--content--field">
              <p>
                {props.card.additional_visitor.title} {props.card.additional_visitor.first_name} {props.card.additional_visitor.last_name} {dates && `${dates}`}
              </p>
            </div>
            {props.card.additional_visitor.profession_or_relationship &&
              <div className="transcription--content--field">
                <h3>Profession/relationship to Zunz</h3>
                <p>{props.card.additional_visitor.profession_or_relationship}</p>
              </div>
            }
            {props.card.additional_visitor.bio &&
              <div className="transcription--content--field">
                <h3>Biography</h3>
                <p>{props.card.additional_visitor.bio}</p>
              </div>
            }
            {props.card.additional_visitor_place &&
              <div className="transcription--content--field">
                <h3>Place associated with visitor</h3>
                <p>{props.card.additional_visitor_place}</p>
              </div>
            }
          </div>
        }

        {
          // Various
        }

        {props.card.remarks && <hr />}

        {props.card.remarks &&
          <div className="transcription--content--field">
            <h3>Remarks</h3>
            <p>{props.card.remarks}</p>
          </div>
        }

      </div>

      </div>
    </div>
  )
}

import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Home from "./pages/Home"
import Card from "./pages/Card"
import "./App.css"

export default function App() {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  // const [cards, setCards] = useState([])
  const [cards, setCards] = useState(() => checkCards())
  // console.log("cards:", cards)

  function checkCards() {
    const cardsLocalStorage = localStorage.getItem("cards")
    if (cardsLocalStorage && cardsLocalStorage.length > 0) {
      setIsLoaded(true)
      return JSON.parse(cardsLocalStorage)
    } else {
      return []
    }
  }

  useEffect(() => {
    const time = Date.now()
    sessionStorage.setItem('timeAppLaunched', time)
  }, [])

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    if (cards.length === 0) {
      // const url = "http://127.0.0.1:8000/cards/"
      const url = "https://zunz-api.lbi.org/cards/"
      
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true)
            setCards(result)
            localStorage.setItem("cards", JSON.stringify(result))
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true)
            setError(error)
          }
        )
    }
  }, [])

  // Pre-download all card images
  useEffect(() => {
    for (let i = 0; i < cards.length; i++) {
      const frontImg = new Image()
      frontImg.src = cards[i]["front_image"].replace(".jpg", "_test.jpg")
      // console.log(frontImg.src)

      if (cards[i]["back_image"]) {
        const backImg = new Image()
        backImg.src = cards[i]["back_image"].replace(".jpg", "_test.jpg")
        // console.log(backImg.src)
      }
    }
  }, [cards])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home cards={cards} error={error} isLoaded={isLoaded} setCards={setCards} setIsLoaded={setIsLoaded} setError={setError} />} />
        <Route path=":cardId" element={<Card cards={cards} error={error} isLoaded={isLoaded} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

import { useEffect, useState, useCallback, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Transcription from "../components/Transcription"
import Carousel from 'react-bootstrap/Carousel'
import {getDates} from '../utilities'
// import 'bootstrap/dist/css/bootstrap.css'
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom"
import arrowBackIcon from '../images/arrow_back.svg'
import openTextIcon from '../images/open_text.svg'
import flipIcon from '../images/flip.svg'
import fallBackImg from "../images/blank.png"

export default function Card(props) {
  const [error, setError] = useState(null)
  // const [isLoaded, setIsLoaded] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [cardIsFlipped, setCardIsFlipped] = useState(false)
  const [showTranscription, setShowTranscription] = useState(false)
  // const [carouselTouch, setCarouselTouch] = useState(true)
  const [scaleReset, setScaleReset] = useState(true)
  const { cardId } = useParams()
  const navigate = useNavigate()
  const [card, setCard] = useState(
    () => props.cards.find(element => String(element.id) === cardId)
  )

  useEffect(() => {
    if (card !== undefined) {
      // props.isLoaded(true)
      setCurrentImage(card.front_image.replace(".jpg", "_test.jpg"))
    }
  }, [])

  // Fetch data from API
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  // useEffect(() => {
  //   if (card === undefined) {
  //     // const url = `http://127.0.0.1:8000/cards/${cardId}/`
  //     const url = `https://zunz-api.lbi.org/cards/${cardId}/`

  //     fetch(url) // 
  //       .then(res => res.json())
  //       .then(
  //         (result) => {
  //           setIsLoaded(true)
  //           setCard(result)
  //           setCurrentImage(result.front_image.replace(".jpg", "_test.jpg"))
  //         },
  //         // Note: it's important to handle errors here
  //         // instead of a catch() block so that we don't swallow
  //         // exceptions from actual bugs in components.
  //         (error) => {
  //           setIsLoaded(true)
  //           setError(error)
  //         }
  //       )
  //   }
  // }, [cardId])

  function handleClickOpen(event) {
    setShowTranscription(current => !current)
  }

  function handleClickFlip(event) {
    if (cardIsFlipped) {
      setCurrentImage(card.front_image.replace(".jpg", "_test.jpg"))
    } else {
      setCurrentImage(card.back_image.replace(".jpg", "_test.jpg"))
    }
    setCardIsFlipped(current => !current)
  }

  // function isImgTransformDefault() {
  //   const computed = window.getComputedStyle(imgRef.current).getPropertyValue("transform");
  //   console.log("transform value:", computed)
  //   console.log("isImgTransformDefault?", computed.startsWith("matrix(1, 0, 0"))
  //   // console.log(testRef.current)
  //   return computed.startsWith("matrix(1, 0, 0")
  // }

  function handleSlide() {
    // console.log("currentImage:", currentImage)
    const activeImg = document.getElementsByClassName("carousel-item active")[0].getElementsByTagName("img")[0]
    // console.log("activeImg:", activeImg)
    activeImg.style.transform = "scale3d(1, 1, 1) translate3d(0px, 0px, 0px)"
    // console.log("isImgTransformDefault()", isImgTransformDefault())
  }

  // const childRefs = [
  //   useRef(),
  //   useRef(),
  //   useRef(),
  //   useRef()
  // ]

  const imgRefs = [
    useRef(),
    useRef()
  ]

  // react-quick-pinch-zoom
  const onUpdate0 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRefs[0]

    if (img) {
      const value = make3dTransformValue({ x, y, scale })
      img.style.setProperty("transform", value)
    }

    // console.log("onUpdate0 scale:", scale)
    if ((scale === 0) || (scale >= 0.98 && scale <= 1)) {
      setScaleReset(true)
    } else {
      setScaleReset(false)
    }
  }, [])

  // react-quick-pinch-zoom
  const onUpdate1 = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRefs[1]

    if (img) {
      const value = make3dTransformValue({ x, y, scale })
      img.style.setProperty("transform", value)
    }

    // console.log("onUpdate1 scale:", scale)
    if ((scale === 0) || (scale >= 0.98 && scale <= 1)) {
      setScaleReset(true)
    } else {
      setScaleReset(false)
    }
  }, [])


  function handleOnSlide (eventKey) {
    // childRefs[eventKey].current.getAlert()
    // console.log(imgRefs[eventKey])
    let key = eventKey
    if (key === 0) {
      key = imgRefs.length-1
    } else {
      key -= 1
    }
    const transform = window.getComputedStyle(imgRefs[key].current).getPropertyValue("transform")
    // console.log(`${key}:`, "transform:", transform)
    imgRefs[key].current.style.transform = ""
    // imgRefs[key].current.style.transform = "scale3d(1, 1, 1) translate3d(0px, 0px, 0px)"
    // console.log(imgRefs[key].current)
  }

  // console.log("card (Card.js):", card)

  if (error) {
    return (
      <div className='card'>
        <div className="message">Temporarily unavailable... <a href="/"><b>RELOAD</b></a></div>
      </div>
    )
  } else if (!props.isLoaded) {
    return (
      <div className='card'>
        <div className="message">Loading...</div>
      </div>
    )
  } else {
    return (
      <div className='card'>
        {showTranscription
          ? <Transcription card={card} setShowTranscription={setShowTranscription} />
          :
            <div>
              <Carousel controls={false} indicators={false} interval={null} touch={false}>
                <Carousel.Item>
                  <QuickPinchZoom onUpdate={onUpdate0} minZoom={0.96} draggableUnZoomed={false}>
                    <img
                      className="d-block w-100"
                      src={currentImage}
                      // src="https://zunz-api.lbi.org/media/007-Adler_test.jpg"
                      alt={`Card from ${card.visitor.title} ${card.visitor.first_name} ${card.visitor.last_name}`}
                      ref={imgRefs[0]}
                      onError={(e) => (e.currentTarget.src = fallBackImg)}
                    />
                  </QuickPinchZoom>
                  {/* <img
                    className="d-block w-100"
                    src="https://zunz-api.lbi.org/media/007-Adler_test.jpg"
                    alt="Second slide"
                  /> */}
                  {/* <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  </Carousel.Caption> */}
                </Carousel.Item>
              </Carousel>
              <footer className="card--controls">

                <div className="card--controls--left">
                  <div className="card--controls--left--back" onClick={() => navigate(-1)}>
                    <img className="card--controls--left--back--icon" src={arrowBackIcon} alt="Back" />
                    <div className="card--controls--left--back--label">BACK</div>
                  </div>

                  {card.back_image
                  &&
                    <div className="card--controls--left--flip" onClick={handleClickFlip}>
                      <img className="card--controls--left--flip--icon" src={flipIcon} alt="Flip card" />
                      <div className="card--controls--left--flip--label">FLIP</div>
                    </div>
                  }
                </div>

                <div className="card--controls--center">
                  {card.visitor.title} {card.visitor.first_name} {card.visitor.last_name} {getDates(card.visitor.birth_date, card.visitor.death_date)}
                </div>

                <div className="card--controls--right">
                  <div className="card--controls--right--text" onClick={handleClickOpen}>
                    <img className="card--controls--right--text--icon" src={openTextIcon} alt="Open text" />
                    <span className="card--controls--right--text--label">TEXT</span>
                  </div>
                </div>

              </footer>
            </div>
        }
      </div>
    )
  }
  // }
}

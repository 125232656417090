import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import {getDates} from '../utilities'
import arrowUpIcon from '../images/arrow_up.svg'
import fallBackImg from "../images/blank.png"

export default function Home(props) {
  const cards = props.cards
  let cardElements = []

  // const location = useLocation();
  // console.log("location:", location)

  // Fetch data if a specified amount of time has passed
  // useEffect(() => {
  //   const time = Date.now()
  //   const timeAppLaunched = sessionStorage.getItem('timeAppLaunched')
  //   const timeDiff = (time-timeAppLaunched)/1000/60/60 // in hours
  //   // const timeDiff = (time-timeAppLaunched)/1000 // in seconds

  //   if (timeDiff >= 24) {
  //     sessionStorage.setItem('timeAppLaunched', time)

  //     // const url = "http://127.0.0.1:8000/cards/"
  //     const url = "https://zunz-api.lbi.org/cards/"
      
  //     fetch(url)
  //       .then(res => res.json())
  //       .then(
  //         (result) => {
  //           props.setIsLoaded(true)
  //           props.setcards(result)
  //         },
  //         // Note: it's important to handle errors here
  //         // instead of a catch() block so that we don't swallow
  //         // exceptions from actual bugs in components.
  //         (error) => {
  //           props.setIsLoaded(true)
  //           props.setError(error)
  //         }
  //       )
  //   }
  // }, [location]);

  // Remember page scroll position when returning to this page
  useEffect(() => {
    if (cards.length > 0) {
      const scrollPosition = sessionStorage.getItem('scrollPosition')
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition, 10))
        sessionStorage.removeItem('scrollPosition')
      }
    }
  }, [cards])

  cardElements = cards.map(function (card) {
    if (card.front_image && card.front_image_small) {
      return (<li className="home--main--thumbnail" key={card.id}>
        <Link
          to={`${card.id}`}
          onClick={() =>
              sessionStorage.setItem('scrollPosition', window.pageYOffset)
          }
        >
          <div className="imageWrapper">
            <img
              src={card.front_image_small}
              alt={`Card from ${card.visitor.title} ${card.visitor.first_name} ${card.visitor.last_name}`}
              onError={(e) => (e.currentTarget.src = fallBackImg)}
            />
          </div>
          <div>{card.visitor.title} {card.visitor.first_name} {card.visitor.last_name} {getDates(card.visitor.birth_date, card.visitor.death_date)}</div>
        </Link>
      </li>)
    } else {
      return null
    }
  })

  function handleOnClick() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    }); 
  }

  // Toggle scroll button based on scroll position
  useEffect(() => {
    const btnScrollToTop = document.querySelector(".home--scroll");
    window.addEventListener('scroll', e => {
      btnScrollToTop.style.display = window.scrollY > 20 ? 'block' : 'none';
    });
  }, [])

  if (props.error) {
    return (
      <div className='home'>
        <header className="home--header">
          <h1>Leopold and Adelheid Zunz <span>Calling Cards</span></h1>
        </header>
        <div className="message">Temporarily unavailable... <a href="/"><b>RELOAD</b></a></div>
        <div className='home--scroll' onClick={handleOnClick}>
          <img src={arrowUpIcon} alt="Scroll to Top" />
          <div className="icon-label">TOP</div>
        </div>
      </div>
    )
  } else if (!props.isLoaded) {
    return (
      <div className='home'>
        <header className="home--header">
          <h1>Leopold and Adelheid Zunz <span>Calling Cards</span></h1>
        </header>
        <div className="message">Loading...</div>
        <div className='home--scroll' onClick={handleOnClick}>
          <img src={arrowUpIcon} alt="Scroll to Top" />
          <div className="icon-label">TOP</div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='home'>
        <header className="home--header">
          <h1>Leopold and Adelheid Zunz <span>Calling Cards</span></h1>
        </header>
        <div className='home--main'>
          {cardElements.length >= 0 && cardElements}
        </div>
        <div className='home--scroll' onClick={handleOnClick}>
          <img src={arrowUpIcon} alt="Scroll to Top" />
          <div className="icon-label">TOP</div>
        </div>
      </div>
    )
  }
}

export function getDates(birth_date, death_date) {
  birth_date = birth_date || ""
  death_date = death_date || ""
  if (birth_date || death_date) {
    return `(${birth_date}-${death_date})`
  } else {
    return ""
  }
}

export function getVisitDate(year, month, day) {
  const months = [ "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December" ]
  let date = ""
  let partialDate = ""

  if (year) {
    if (month) {
        partialDate = months[month-1] + " "

        if (day) {
            partialDate = `${partialDate} ${day}, `
        }
    }
    date = `${partialDate}${year}`
  }
  return date
}
